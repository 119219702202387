import React, {useEffect} from 'react';
import "./HeroSelector.scss";

interface HeroSelectorProps {
    onSelect?: (hero: Hero) => void;
}

interface Hero {
    id: string;
    name: string;
    image: string;
}

function HeroSelector(props: HeroSelectorProps) {
    const [heroes, setHeroes] = React.useState<Hero[]>([]);
    useEffect(() => {
        const fetchHeroes = async function fetchHeros() {
            const results = await fetch("https://api.superheros.lb.quentin.paris/items/heros", {
                headers: {
                    "Authorization": "Bearer FcXmqLMfAGICUMRBJ7OUeloa7nW7itSo"
                }
            })
            const json = await results.json();
            setHeroes(json["data"].map((hero: any) => ({
                id: hero["id"],
                name: hero["name"],
                image: "https://api.superheros.lb.quentin.paris/assets/" + hero.image + "?access_token=FcXmqLMfAGICUMRBJ7OUeloa7nW7itSo"
            })));
        }
        fetchHeroes();
    }, []);
    return (
        <div className={"hero-selector"}>
            {heroes.map((hero) => (
                <button className={"hero"} key={hero.id} onClick={event => props.onSelect && props.onSelect(hero)}>
                    <img src={hero.image} alt={hero.name}/>
                    {hero.name}
                </button>
            ))}
        </div>
    );
}

export default HeroSelector;
export type {Hero};
