import * as React from 'react';
import './Header.scss';
import eclair from './eclair.svg';
const Header = () => {
    return <div className={"header"}>
        <img src={eclair} alt={"logo"} className={"logo"}/>
        La Centrale des Heros
    </div>
}

export default Header;