import React, {useEffect} from 'react';
import Header from './Header';
import './App.scss';
import HeroSelector, {Hero} from "./HeroSelector";
import PhoneInput from "react-phone-number-input/input";
import Message from "./Message";
import {CardModel} from "./Card";


interface ActionsProps {
    children: React.ReactNode[] | React.ReactNode
}

function Actions(props: ActionsProps) {
    return <div className={"actions"}>
        {props.children}
    </div>
}

interface ConversationProps {
    card: CardModel;
}

function Conversation(props: ConversationProps) {
    const [messageNumber, setMessageNumber] = React.useState(1);
    const [firstName, setFirstName] = React.useState<string | null>(null);
    const [gender, setGender] = React.useState<string | null>(null);
    const [age, setAge] = React.useState<number | null>(null);
    const [hero, setHero] = React.useState<Hero | null>(null);
    const [informations, setInformations] = React.useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(undefined);
    const [error, setError] = React.useState<boolean>(false);
    const [done, setDone] = React.useState<boolean>(false);
    const ref = React.useRef<HTMLDivElement>(null);

    const showMessage = (number: number) => {
        setMessageNumber(number);
    }

    const updateScroll = () => {
        if (ref.current) {
            const allMessages = ref.current.querySelectorAll(".message");
            const lastMessage = allMessages[allMessages.length - 1];
            lastMessage?.scrollIntoView({behavior: "smooth"});
        }
    }

    useEffect(() => {
        updateScroll();
    }, [messageNumber]);

    const sendTicket = async () => {
        try {
            await fetch("https://api.superheros.lb.quentin.paris/items/tickets", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer FcXmqLMfAGICUMRBJ7OUeloa7nW7itSo",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: firstName,
                    age: age,
                    gender: gender === "boy" ? "male" : "female",
                    phone: phoneNumber?.replace("+", "00"),
                    hero: hero?.id,
                    comments: informations,
                    card: props.card.id
                })
            })
            setDone(true);
        } catch (e) {
            setError(true);
        }
    }

    return (
        <>
            <Header></Header>

            <div className={"conversation"} ref={ref}>
                <Message timing={1} typing={true} onDoneTyping={() => showMessage(2)}>
                    Salut c’est Eclair ! 👋 Je suis l’assistant des super héros !
                </Message>

                {messageNumber >= 2 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(3)}>
                        Et toi comment t’appelles tu ? 😊
                    </Message>}

                {messageNumber === 3 && <Actions>
                    <input type="text" placeholder="ton prénom" onChange={e => setFirstName(e.target.value)}/>

                    <button className={"button"} onClick={() => {
                        showMessage(4)
                    }}>Envoyer
                    </button>
                </Actions>}

                {messageNumber >= 4 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    Je m'appelle {firstName}
                </Message>}

                {messageNumber >= 4 &&
                    <Message timing={2} typingTiming={0} typing={true} onDoneTyping={() => setMessageNumber(5)}>
                        Enchanté {firstName} ! Et tu es un garçon ou une fille ?
                    </Message>
                }

                {messageNumber === 5 && <Actions>
                    <button className={"button"} onClick={() => {
                        setGender("boy");
                        showMessage(6)
                    }}>Garçon
                    </button>
                    <button className={"button"} onClick={() => {
                        setGender("girl");
                        showMessage(6)
                    }}>Fille
                    </button>
                </Actions>}

                {messageNumber >= 6 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    {gender === "boy" ? "Je suis un garçon" : "Je suis une fille"}
                </Message>}

                {messageNumber >= 6 &&
                    <Message timing={1} typingTiming={0} typing={true} onDoneTyping={() => showMessage(7)}>
                        Ravi de te rencontrer {firstName}. Es-tu prêt pour l’aventure ? 🤠
                    </Message>}

                {messageNumber === 7 && <Actions>
                    <button className={"button"} onClick={() => {
                        setMessageNumber(8)
                    }}>Oui
                    </button>
                </Actions>}

                {messageNumber >= 8 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    Oui
                </Message>}

                {messageNumber >= 8 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(9)}>
                        Parfait ! Pour commencer, si je te racontais mon histoire ?
                    </Message>}

                {messageNumber === 9 && <Actions>
                    <button className={"button"} onClick={() => {
                        setMessageNumber(10)
                    }}>Vas-y !
                    </button>
                </Actions>}

                {messageNumber >= 10 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    Vas-y !
                </Message>}

                {messageNumber >= 10 &&
                    <Message timing={4} typingTiming={1} typing={true} onDoneTyping={() => showMessage(11)}>
                        Il y a bien longtemps, dans un monde où les super-héros et leurs pouvoirs étaient encore en
                        train de prendre forme, je suis né. Créé pour être l'assistant des super héros, je suis unique
                        dans ce vaste univers ☄️.
                    </Message>}

                {messageNumber === 11 && <Actions>
                    <button className={"button"} onClick={() => {
                        setMessageNumber(12)
                    }}>L'assistant des super héros ?
                    </button>
                </Actions>}

                {messageNumber >= 12 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    L'assistant des super héros ?
                </Message>}

                {messageNumber >= 12 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(13)}>
                        Oui ! Mon rôle est d'aider lorsque les super-héros en ont besoin.
                    </Message>}

                {messageNumber >= 13 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(14)}>
                        Prends Spider-Man, par exemple, ce super-héros capable de grimper aux murs et de lancer des
                        toiles.
                    </Message>}

                {messageNumber === 14 && <Actions>
                    <button className={"button"} onClick={() => {
                        setMessageNumber(15)
                    }}>Oui ok, je vois !
                    </button>
                </Actions>}

                {messageNumber >= 15 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    Oui ok, je vois !
                </Message>}

                {messageNumber >= 15 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(16)}>
                        Bien ! Une fois, il a dû éteindre un incendie géant qui menaçait tout un quartier.
                    </Message>}

                {messageNumber >= 16 &&
                    <Message timing={3} typingTiming={1} typing={true} onDoneTyping={() => showMessage(17)}>
                        Grâce à mon idée d'utiliser l'eau de la rivière, nous avons pu éteindre toutes les flammes et
                        sauver tous les habitants.
                    </Message>}

                {messageNumber === 17 && <Actions>
                    <button className={"button"} onClick={() => {
                        setMessageNumber(18)
                    }}>Waho!
                    </button>
                </Actions>}

                {messageNumber >= 18 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    Waho!
                </Message>}

                {messageNumber >= 18 &&
                    <Message timing={4} typingTiming={2} typing={true} onDoneTyping={() => showMessage(19)}>
                        Cependant, je ne me contente pas seulement de résoudre des problèmes.
                    </Message>
                }

                {messageNumber >= 19 &&
                    <Message timing={4} typingTiming={2} typing={true} onDoneTyping={() => showMessage(20)}>
                        Je suis aussi un ami secret, quelqu'un sur qui les super-héros peuvent compter pour des conseils
                        et des encouragements.
                    </Message>
                }

                {messageNumber >= 20 &&
                    <Message timing={4} typingTiming={2} typing={true} onDoneTyping={() => showMessage(21)}>
                        Ensemble, nous formons une équipe solide, prête à relever tous les défis qui se présentent.
                    </Message>
                }

                {messageNumber >= 21 &&
                    <Message timing={4} typingTiming={2} typing={true} onDoneTyping={() => showMessage(22)}>
                        Mon histoire a traversé les époques, et les super-héros savent que je suis là pour les soutenir,
                        même si les humains ne me voient pas toujours. Partout où il y a un super-héros, je suis là pour
                        les aider à briller encore plus fort.
                    </Message>
                }

                {messageNumber >= 22 &&
                    <Message timing={4} typingTiming={2} typing={true} onDoneTyping={() => showMessage(23)}>
                        Voilà, c'est moi, Éclair, l'assistant loyal et astucieux des super-héros, prêt à rendre chaque
                        aventure incroyablement éclatante ! Et aujourd’hui je suis là pour toi, pour t'aider à contacter
                        ton super-héros ! ⚡
                    </Message>
                }

                {messageNumber >= 23 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(24)}>
                        Dis-moi {firstName}, quel âge as-tu ?
                    </Message>
                }

                {messageNumber === 24 && <Actions>
                    <select name={"age"} onChange={e => setAge(parseInt(e.target.value))}>
                        <option value={""}>Quel âge as-tu ?</option>
                        <option value={6}>6 ans</option>
                        <option value={7}>7 ans</option>
                        <option value={8}>8 ans</option>
                        <option value={9}>9 ans</option>
                        <option value={10}>10 ans</option>
                        <option value={11}>11 ans</option>
                        <option value={12}>12 ans</option>
                        <option value={13}>13 ans</option>
                        <option value={14}>14 ans</option>
                        <option value={15}>15 ans</option>
                        <option value={16}>16 ans</option>
                        <option value={17}>17 ans</option>
                        <option value={18}>18 ans</option>
                    </select>

                    <button className={"button"} onClick={() => {
                        showMessage(25)
                    }}>Valider
                    </button>

                </Actions>}

                {messageNumber >= 25 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    J'ai {age} ans !
                </Message>}

                {messageNumber >= 25 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(26)}>
                        Super !
                    </Message>}

                {messageNumber >= 26 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(27)}>
                        Pourquoi as-tu besoin de mes super pouvoirs ?
                    </Message>}

                {messageNumber === 27 && <Actions>
                    <button className={"button"} onClick={() => {
                        showMessage(28)
                    }}>J'aimerais contacter un super héros 🦸
                    </button>
                </Actions>}

                {messageNumber >= 28 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    J'aimerais contacter un super héros 🦸
                </Message>}

                {messageNumber >= 28 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(29)}>
                        Super ! Quel est ton super héros préféré ?
                    </Message>}

                {messageNumber === 29 && <Actions>
                    <HeroSelector onSelect={hero => {
                        setHero(hero);
                        showMessage(30);
                    }}/>
                </Actions>}

                {messageNumber >= 30 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    Mon super héros préféré est {hero?.name} !
                </Message>}

                {messageNumber >= 30 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(31)}>
                        C’est noté {firstName} ! Je transmets le message à ton héros qui te contactera dans les
                        prochains jours.
                    </Message>}

                {messageNumber >= 31 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(32)}>
                        Avant de te dire au revoir {firstName}, peux-tu me passer à tes parents ? J’ai un message pour
                        eux.

                        Clique-sur OK quand c'est bon !
                    </Message>}

                {messageNumber === 32 && <Actions>
                    <button className={"button"} onClick={() => {
                        showMessage(33)
                    }}>OK
                    </button>
                </Actions>}

                {messageNumber >= 33 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    OK
                </Message>}

                {messageNumber >= 33 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(34)}>
                        Avez-vous une information particulière à communiquer au héros ?
                    </Message>}

                {messageNumber === 34 && <Actions>
                    <textarea placeholder={"Votre message"} onChange={e => setInformations(e.target.value)}/>
                    <button className={"button"} onClick={() => {
                        showMessage(35)
                    }}>Envoyer
                    </button>
                </Actions>}

                {messageNumber >= 35 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    {informations}
                </Message>}

                {messageNumber >= 35 &&
                    <Message timing={2} typingTiming={1} typing={true} onDoneTyping={() => showMessage(36)}>
                        Pour que le super héro puisse contacter {firstName}, j’ai besoin d’un numéro de téléphone
                        portable.
                    </Message>}

                {messageNumber === 36 && <Actions>
                    <PhoneInput
                        placeholder="Numéro de téléphone"
                        onChange={number => setPhoneNumber(number)}
                        defaultCountry={"FR"}
                    />
                    <button className={"button"} onClick={() => {
                        showMessage(37)
                        sendTicket()
                    }}>Envoyer
                    </button>
                </Actions>}

                {messageNumber >= 37 && <Message timing={0} typingTiming={0} typing={false} other={true}>
                    {phoneNumber}
                </Message>}

                {done && <Message timing={2} typingTiming={1} typing={true}>
                    Merci ! Je transmets le message à votre super héros qui vous contactera dans les prochains jours.
                    Si vous n'avez pas de nouvelles d'ici 2 jours, n'hésitez pas à nous contacter à l'adresse suivante :
                    contact@lacentraledesheros.com
                </Message>}

                {error && <Message timing={2} typingTiming={1} typing={true}>
                    Une erreur est survenue. Veuillez réessayer plus tard.
                </Message>}


            </div>
        </>
    );
}

export default Conversation;
