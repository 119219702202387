import React, {useEffect} from "react";
import avatar from "./avatar.png";

interface MessageProps {
    children: React.ReactNode[] | React.ReactNode
    typing?: boolean
    other?: boolean
    timing?: number
    typingTiming?: number
    onDoneTyping?: () => void
}

function Message(props: MessageProps) {
    const [typing, setTyping] = React.useState(props.typing)
    const [timingDone, setTimingDone] = React.useState(false)
    const [hidden, setHidden] = React.useState(true);

    const ref = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!hidden) {
            ref.current?.scrollIntoView({behavior: "smooth"});
        }
    }, [hidden]);

    setTimeout(() => {
        setHidden(false);
    }, props.typingTiming ? (props.typingTiming * 1000) : 0);


    useEffect(() => {
        setTimeout(() => {
            setTimingDone(true);
            setTyping(false);
            props.onDoneTyping && props.onDoneTyping();
        }, props.timing ? (props.timing * 1000) : 0);
    }, []);


    return <div className={`message-container ${props.other ? 'other' : ''}`}>
        {!hidden && !props.other && <img src={avatar} className={"avatar"}></img>}
        {!hidden && <div ref={ref} className={`message ${typing ? 'typing' : ''} ${props.other ? 'other' : ''}`}>
            {timingDone && props.children}

            {typing && <><span></span>
                <span></span>
                <span></span></>}
        </div>}</div>
}

export default Message;