import useHash from "./useHash";
import React, {useEffect} from "react";
import Conversation from "./App";
import avatar from "./avatar.png";

interface CardModel {
    id: string;
    description: string;
    status: string;
}

interface CardProps {

}

interface CardErrorProps {
    card: CardModel|undefined|null;
}

const CardError = ({card}: CardErrorProps) => {
    return (
        <>
            {card === undefined && <div className={"all-centered"}>
                <img src={avatar}></img>
            </div>}
            {card === null && <div>Carte introuvable</div>}
            {card && card.status === "disabled" && <div>Carte désactivée</div>}
        </>
    );
}

const Card = () => {
    const [cardId] = useHash();
    const [card, setCard] = React.useState<CardModel|undefined|null>(undefined);

    useEffect(() => {
        if (cardId) {
            const fetchCard = async function fetchCard() {
                try {
                    const results = await fetch("https://api.superheros.lb.quentin.paris/items/cartes/" + cardId, {
                        headers: {
                            "Authorization": "Bearer FcXmqLMfAGICUMRBJ7OUeloa7nW7itSo"
                        }
                    })

                    if (results.status === 404 || results.status === 403) {
                        setCard(null);
                        return;
                    } else {
                        const json = await results.json();
                        setCard(json["data"]);
                    }
                } catch (e) {
                    setCard(null);
                }
            }

            fetchCard();
        }
    }, [cardId]);


    return (
        <>
            { (card && (card.status === "enabled" || card.status === "permanent"))
                ? <Conversation card={card}></Conversation>
                : <CardError card={card}></CardError>
            }

        </>
    );
}

export default Card;
export type {CardModel};