import React from "react";

const useHash = (): [string, (newHash: string) => void] => {
    const currentHash = window.location.hash.replace(/^#/, "");

    const [hash, setHash] = React.useState<string>(() => currentHash);

    const hashChangeHandler = React.useCallback(() => {
        setHash(currentHash);
    }, []);

    React.useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
    }, []);

    const updateHash = React.useCallback(
        (newHash: string) => {
            if (newHash !== hash) {
                window.location.hash = "#" + newHash;
            }
        },
        [hash]
    );

    return [hash, updateHash];
};


export default useHash;